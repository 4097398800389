import React, { useEffect, useState } from "react";
import { Accordion } from 'react-bootstrap';
import { stripHtmlUsingRegex } from "../../../utils/validate";
const ProductFeatures = ({ productList, productDetailList, multiVariant }) => {
    let selectedAttrValue = productDetailList.find(data => data.attrValueID === multiVariant)

    if (!selectedAttrValue) selectedAttrValue = productDetailList[0]
    return (
        <div className="pdt-features">
            <Accordion>
                {productList[0]?.productFeatures ?
                    <Accordion.Item key={1} eventKey={1}>
                        <Accordion.Header>Features</Accordion.Header>
                        <Accordion.Body>
                            <p><div dangerouslySetInnerHTML={{ __html: productList[0]?.productFeatures }} /></p>
                        </Accordion.Body>
                    </Accordion.Item> : ""}
                {productList[0]?.productSpecification?.some(item => item?.value) ? (
                    <Accordion.Item key={2} eventKey={2}>
                        <Accordion.Header>Specifications</Accordion.Header>
                        <Accordion.Body>
                            <div className="data-table">
                                <table>
                                    <tbody>
                                        {productList[0]?.productSpecification
                                            ?.filter(item => item?.value) // Filter out items with no value
                                            .map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.value}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ) : null}
                {(selectedAttrValue?.dataSheet || selectedAttrValue?.submittal || selectedAttrValue?.catalog || selectedAttrValue?.iom) ? <Accordion.Item key={3} eventKey={3}>
                    <Accordion.Header>Downloads</Accordion.Header>
                    <Accordion.Body>
                        <div className="data-table">
                            <table>
                                {selectedAttrValue?.dataSheet ? (<tr>
                                    <td><a href={selectedAttrValue?.dataSheet} className="download">Datasheet</a></td>
                                    <td className="text-end"><a href={selectedAttrValue?.dataSheet}>Download</a></td>
                                </tr>) : ""}
                                {selectedAttrValue?.submittal ? (<tr>
                                    <td><a href={selectedAttrValue?.submittal} className="download">Submittal</a></td>
                                    <td><a href={selectedAttrValue?.submittal}>Download</a></td>
                                </tr>) : ""}
                                {selectedAttrValue?.catalog ? (<tr>
                                    <td><a href={selectedAttrValue?.catalog} className="download">Catalog</a></td>
                                    <td><a href={selectedAttrValue?.catalog}>Download</a></td>
                                </tr>) : ""}
                                {selectedAttrValue?.iom ? (<tr>
                                    <td><a href={selectedAttrValue?.iom} className="download">IOM - Installation Operation Manual</a></td>
                                    <td><a href={selectedAttrValue?.iom}>Download</a></td>
                                </tr>) : ""}
                            </table>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> : ""}
            </Accordion>
        </div>
    )
}

export default ProductFeatures