import React, { useRef, useState, useEffect, useMemo, useContext } from "react";
import { Row, Col, CardBody, Card, Container } from "react-bootstrap";
import ListPagination from "../components/listPagination";
import Dropdown from 'react-bootstrap/Dropdown';
import ProductGrid from "./ProductGrid";
import { CheckNull } from "../utils/validate";
import { GetAllProducts } from "../providers/queryData";
import { resourceconstant } from "../constants";
import ProductNewFilter from "./ProductNewFilter"
import useProducts from "../hooks/useProducts";
import { SearchValueContext } from "../context/SearchValueContext";
import { Link, useNavigate } from "react-router-dom";

const ProductListQuery = ({ page, mainCategoryId, subCategoryId, subCategory, mainCategory, searchTerm, mainCategoryImage, subCategoryDetail, allProduct }) => {
    const navigate = useNavigate();
    const [selectedMainCategories, setSelectedMainCategories] = useState([])
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [selectedBrands, setSelectedBrands] = useState([])
    const [selectedRatings, setSelectedRatings] = useState([])
    const [sorting, setSorting] = useState("newestfirst")
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [productFilterList, setProductFilterList] = useState([])
    const [productListloading, setProductListLoading] = useState(true);
    const [displayMainCategories, setDisplayMainCategories] = useState([])
    const [displaySubCategories, setDisplaySubCategories] = useState([])
    const [displayBrands, setDisplayBrands] = useState([])
    const [responseData, setResponseData] = useState({})
    const [productListDisplay, setProductListDisplay] = useState([]);
    const [productsList, setProductsList] = useProducts();
    const [selectionOrder, setSelectionOrder] = useState([]);
    const { searchValueTerm, setSearchValueTerm, setSearchTerm, search, setSearch } = useContext(SearchValueContext);
    const [searchProducts, setSearchProducts] = useState("");
    const itemsPerPage = 20;
    const totalPages = Math.ceil(productListDisplay?.length / itemsPerPage);
    const multipleLoading = useRef(false);

    useEffect(() => {
        setProductFilterList(productsList);
    }, [productsList])

    const HandlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedProductList = useMemo(() => {
        if (productListDisplay?.length <= itemsPerPage) setCurrentPage(1);
        else if (
            currentPage > Math.ceil(productListDisplay?.length / itemsPerPage)
        ) {
            setCurrentPage(1);
        }

        const firstPageIndex = (currentPage - 1) * itemsPerPage;
        const lastPageIndex = firstPageIndex + itemsPerPage;
        return productListDisplay?.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, productListDisplay]);

    const HandleMainCategoryChange = (e, id, name) => {
        e.stopPropagation()
        setSelectedMainCategories(prevSelected => {
            let updatedSelected;
            if (prevSelected?.find(category => category?.id === id)) {
                updatedSelected = prevSelected?.filter(categoryId => categoryId?.id !== id);
            } else {
                updatedSelected = [...prevSelected, { id, name }];
            }
            return updatedSelected;
        });
    };

    const HandleSubCategoryChange = (id, name) => {
        setSelectedSubCategories(prevSelected => {
            let updatedSelected;
            if (prevSelected?.find(category => category?.id === id)) {
                updatedSelected = prevSelected?.filter(categoryId => categoryId?.id !== id);
            } else {
                updatedSelected = [...prevSelected, { id, name }];
            }
            return updatedSelected;
        });
    };

    const HandleBrandChange = (id, name) => {
        setSelectedBrands(prevSelected => {
            let updatedSelected;
            if (prevSelected?.find(category => category?.id === id)) {
                updatedSelected = prevSelected?.filter(brandId => brandId?.id !== id);
            } else {
                updatedSelected = [...prevSelected, { id, name }];
            }
            return updatedSelected;
        });
    };

    useEffect(() => {
        setSelectionOrder(prevOrder =>
            selectedBrands?.length > 0
                ? [...new Set([...prevOrder, "brand"])]
                : prevOrder.filter(name => name !== "brand")
        );
    }, [selectedBrands]);

    useEffect(() => {
        setSelectionOrder(prevOrder =>
            selectedMainCategories?.length > 0
                ? [...new Set([...prevOrder, "main"])]
                : prevOrder.filter(name => name !== "main")
        );
    }, [selectedMainCategories]);


    useEffect(() => {
        setSelectionOrder(prevOrder =>
            selectedRatings?.length > 0
                ? [...new Set([...prevOrder, "rating"])]
                : prevOrder.filter(name => name !== "rating")
        );
    }, [selectedRatings]);

    useEffect(() => {
        setSelectionOrder(prevOrder =>
            selectedSubCategories?.length > 0
                ? [...new Set([...prevOrder, "sub"])]
                : prevOrder.filter(name => name !== "sub")
        );
    }, [selectedSubCategories]);

    const HandleRatingChange = (rating) => {
        setSelectedRatings(prevSelectedRatings => {
            let updatedSelected;
            if (prevSelectedRatings.includes(rating)) {
                updatedSelected = prevSelectedRatings?.filter(id => id !== rating);
            } else {
                updatedSelected = [...prevSelectedRatings, rating];
            }
            return updatedSelected;
        });
    };

    const HandleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const HandleClearAll = () => {
        if (page === "allProducts") setSelectedMainCategories([]);
        if (page === "allProducts" || page === "mainCategory") setSelectedSubCategories([]);
        setSelectedBrands([]);
        setSearchQuery("")
        setSelectedRatings([]);
        setDisplayBrands([]);
        setDisplayMainCategories([]);
        setDisplaySubCategories([]);
        setSorting("newestfirst")
        setSearchProducts("");
        if (page === "allProducts") {
            navigate('/all-products')
        }
        let selectedData =
        {
            selectedMainCategories: page === "allProducts" ? [] : selectedMainCategories,
            selectedSubCategories: (page === "allProducts" || page === "mainCategory") ? [] : selectedSubCategories,
            selectedBrands: [],
            selectedRatings: [],
            searchTerm: "",
            sorting: "newestfirst",
            currentPage: 1
        }
        HandleApplyFilter(selectedData);
    };

    const HandleSorting = (value) => {
        setProductListLoading(true)
        setSorting(value)

        let selectedData =
        {
            selectedMainCategories: selectedMainCategories,
            selectedSubCategories: selectedSubCategories,
            selectedBrands: selectedBrands,
            selectedRatings: selectedRatings,
            searchTerm: searchTerm,
            sorting: value,
            currentPage: 1
        }
        HandleApplyFilter(selectedData);
    }

    const HandleApplyFilter = async (selectedData) => {
        let tempArray = [...productsList]
        if (selectedData?.selectedMainCategories?.length > 0)
            tempArray = tempArray?.filter(item => selectedData?.selectedMainCategories?.some(main => main.id === item?.mainCategoryID));
        if (selectedData?.selectedSubCategories?.length > 0)
            tempArray = tempArray?.filter(item => selectedData?.selectedSubCategories?.some(sub => sub.id === item?.subCategoryID));
        if (selectedData?.selectedBrands?.length > 0)
            tempArray = tempArray?.filter(item => selectedData?.selectedBrands?.some(brand => brand.id === item?.brandID));
        if (selectedData?.selectedRatings?.length > 0)
            tempArray = tempArray?.filter(item => selectedData?.selectedRatings?.some(rating => parseInt(item.overallRating) === rating));
        if (CheckNull(selectedData?.searchTerm) !== "") {
            tempArray = tempArray.filter(product =>
                product.productName.replace(/\s+/g, ' ').trim().toLowerCase().includes(selectedData?.searchTerm.toLowerCase()) ||
                product.brandName.toLowerCase().includes(selectedData?.searchTerm.toLowerCase())
            );
        }
        if (selectedData?.sorting) {
            if (selectedData?.sorting === "newestfirst")
                tempArray = [...tempArray].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            if (selectedData?.sorting === "lowtohigh")
                tempArray = [...tempArray].sort((a, b) => a.displaySalePrice - b.displaySalePrice);
            if (selectedData?.sorting === "hightolow")
                tempArray = [...tempArray].sort((a, b) => b.displaySalePrice - a.displaySalePrice);
        }
        setCurrentPage(1)
        setProductListDisplay(tempArray)
        setTimeout(() => {
            setProductListLoading(false);
        }, 500);
    }

    useEffect(() => {
        if (CheckNull(mainCategoryId) !== "" && CheckNull(subCategoryId) === "" && productsList?.length > 0) {
            GetMainSubPage();
        }
    }, [mainCategoryId, productsList])

    useEffect(() => {
        if (CheckNull(subCategoryId) !== "" && CheckNull(mainCategoryId) !== "" && productsList?.length > 0) {
            GetMainSubPage();
        }
    }, [subCategoryId, mainCategoryId, productsList])

    useEffect(() => {
        if (CheckNull(searchTerm) !== "") {
            GetMainSubPage();
        }
    }, [searchTerm])

    const GetMainSubPage = () => {
        if (!multipleLoading.current) {
            try {
                multipleLoading.current = true;
                setSearchProducts(searchTerm)
                setSearch("");
                if (CheckNull(mainCategoryId) !== "" && CheckNull(subCategoryId) === "") {
                    setSelectedMainCategories([{ id: mainCategoryId, name: mainCategory }]);
                    setSelectedSubCategories([]);
                }
                else if (CheckNull(subCategoryId) !== "" && CheckNull(mainCategoryId) !== "") {
                    setSelectedMainCategories([{ id: mainCategoryId, name: mainCategory }]);
                    setSelectedSubCategories([{ id: subCategoryId, name: subCategory }]);
                }
                else {
                    setSelectedSubCategories([]);
                    setSelectedMainCategories([]);
                }
                let selectedData =
                {
                    selectedMainCategories: CheckNull(mainCategoryId) !== "" ? [{ id: mainCategoryId, name: mainCategory }] : [],
                    selectedSubCategories: CheckNull(subCategoryId) !== "" ? [{ id: subCategoryId, name: subCategory }] : [],
                    selectedBrands: [],
                    selectedRatings: [],
                    searchTerm: searchTerm,
                    sorting: "newestfirst",
                    currentPage: 1
                }
                setSelectedBrands([]);
                setDisplayBrands([]);
                setSelectedRatings([]);
                setDisplayMainCategories([]);
                setDisplaySubCategories([]);
                setSorting("newestfirst")
                setSearchQuery("")
                HandleApplyFilter(selectedData);
            } catch (error) {
                console.error(error);
            } finally {
                multipleLoading.current = false;
            }
        }
    }

    const RemoveMainCategory = (item) => {
        setProductListLoading(true)

        setSelectedMainCategories(selectedMainCategories?.filter(data => data.id !== item?.id))
        setDisplayMainCategories(displayMainCategories?.filter(data => data.id !== item?.id))
        let selectedData =
        {
            selectedMainCategories: selectedMainCategories?.filter(data => data.id !== item?.id),
            selectedSubCategories: selectedSubCategories,
            selectedBrands: selectedBrands,
            selectedRatings: selectedRatings,
            searchTerm: searchTerm,
            sorting: sorting,
            currentPage: currentPage
        }
        HandleApplyFilter(selectedData);
    }
    const RemoveSubCategory = (item) => {
        setProductListLoading(true)

        setSelectedSubCategories(selectedSubCategories?.filter(data => data.id !== item?.id))
        setDisplaySubCategories(displaySubCategories?.filter(data => data.id !== item?.id))
        let selectedData =
        {
            selectedMainCategories: selectedMainCategories,
            selectedSubCategories: selectedSubCategories?.filter(data => data.id !== item?.id),
            selectedBrands: selectedBrands,
            selectedRatings: selectedRatings,
            searchTerm: searchTerm,
            sorting: sorting,
            currentPage: currentPage
        }
        HandleApplyFilter(selectedData);
    }
    const RemoveBrand = (item) => {
        setProductListLoading(true)

        setSelectedBrands(selectedBrands?.filter(data => data.id !== item?.id))
        setDisplayBrands(displayBrands?.filter(data => data.id !== item?.id))
        let selectedData =
        {
            selectedMainCategories: selectedMainCategories,
            selectedSubCategories: selectedSubCategories,
            selectedBrands: selectedBrands?.filter(data => data.id !== item?.id),
            selectedRatings: selectedRatings,
            searchTerm: searchTerm,
            sorting: sorting,
            currentPage: currentPage
        }
        HandleApplyFilter(selectedData);
    }

    const truncateText = (text, limit) => {
        if (text?.length > limit) {
            return text.slice(0, limit) + '...';
        }
        return text;
    };

    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    };

    const ApplyFilter = () => {
        setProductListLoading(true)
        let selectedData =
        {
            selectedMainCategories: selectedMainCategories,
            selectedSubCategories: selectedSubCategories,
            selectedBrands: selectedBrands,
            selectedRatings: selectedRatings,
            searchTerm: searchTerm,
            sorting: sorting,
            currentPage: 1
        }
        setDisplayBrands(selectedBrands);
        if (page === "allProducts") setDisplayMainCategories(selectedMainCategories)
        if (page === "allProducts" || page === "mainCategory") setDisplaySubCategories(selectedSubCategories)
        HandleApplyFilter(selectedData);
    }

    const getUniqueObjects = (array, key) => {
        let tempArray = array
        if (key === "mainCategoryID") {
            if (selectionOrder?.[0] !== "main") {
                if (selectedSubCategories?.length > 0)
                    tempArray = tempArray?.filter(item => selectedSubCategories?.some(sub => sub.id === item?.subCategoryID));
                if (selectedBrands?.length > 0)
                    tempArray = tempArray?.filter(item => selectedBrands?.some(brand => brand.id === item?.brandID));
                if (selectedRatings?.length > 0)
                    tempArray = tempArray?.filter(item => selectedRatings?.some(rating => rating === parseInt(item?.overallRating)));
            }
            if (CheckNull(searchProducts) !== "") {
                tempArray = tempArray.filter(product =>
                    product.productName.replace(/\s+/g, ' ').trim().toLowerCase().includes(searchProducts?.toLowerCase()) ||
                    product.brandName.toLowerCase().includes(searchProducts?.toLowerCase())
                );
            }

            tempArray = [...tempArray].sort((a, b) => a?.mainCategory?.localeCompare(b?.mainCategory));

            return [...new Map(tempArray.map((item) => [item[key], item])).values()];
        }
        if (key === "subCategoryID") {
            if (selectionOrder?.[0] !== "sub") {
                if (selectedMainCategories?.length > 0)
                    tempArray = tempArray?.filter(item => selectedMainCategories?.some(main => main.id === item?.mainCategoryID));
                if (selectedBrands?.length > 0)
                    tempArray = tempArray?.filter(item => selectedBrands?.some(brand => brand.id === item?.brandID));
                if (selectedRatings?.length > 0)
                    tempArray = tempArray?.filter(item => selectedRatings?.some(rating => rating === parseInt(item?.overallRating)));
            }
            if (CheckNull(searchProducts) !== "") {
                tempArray = tempArray.filter(product =>
                    product.productName.replace(/\s+/g, ' ').trim().toLowerCase().includes(searchProducts?.toLowerCase()) ||
                    product.brandName.toLowerCase().includes(searchProducts?.toLowerCase())
                );
            }
            tempArray = [...tempArray].sort((a, b) => a?.subCategory?.localeCompare(b?.subCategory));

            return [...new Map(tempArray.map((item) => [item[key], item])).values()];
        }
        if (key === "brandID") {
            if (selectionOrder?.[0] !== "brand") {
                if (selectedMainCategories?.length > 0)
                    tempArray = tempArray?.filter(item => selectedMainCategories?.some(main => main.id === item?.mainCategoryID));
                if (selectedSubCategories?.length > 0)
                    tempArray = tempArray?.filter(item => selectedSubCategories?.some(sub => sub.id === item?.subCategoryID));
                if (selectedRatings?.length > 0)
                    tempArray = tempArray?.filter(item => selectedRatings?.some(rating => rating === parseInt(item?.overallRating)));
            }

            if (CheckNull(searchProducts) !== "") {
                tempArray = tempArray.filter(product =>
                    product.productName.replace(/\s+/g, ' ').trim().toLowerCase().includes(searchProducts?.toLowerCase()) ||
                    product.brandName.toLowerCase().includes(searchProducts?.toLowerCase())
                );
            }
            tempArray = [...tempArray].sort((a, b) => a?.brandName?.localeCompare(b?.brandName));

            return [...new Map(tempArray.map((item) => [item[key], item])).values()];

        }
        if (key === "overallRating") {
            if (selectionOrder?.[0] !== "rating") {
                if (selectedMainCategories?.length > 0)
                    tempArray = tempArray?.filter(item => selectedMainCategories?.some(main => main.id === item?.mainCategoryID));

                if (selectedSubCategories?.length > 0)
                    tempArray = tempArray?.filter(item => selectedSubCategories?.some(sub => sub.id === item?.subCategoryID));

                if (selectedBrands?.length > 0)
                    tempArray = tempArray?.filter(item => selectedBrands?.some(brand => brand.id === item?.brandID));
            }
            if (CheckNull(searchProducts) !== "") {
                tempArray = tempArray.filter(product =>
                    product.productName.replace(/\s+/g, ' ').trim().toLowerCase().includes(searchProducts?.toLowerCase()) ||
                    product.brandName.toLowerCase().includes(searchProducts?.toLowerCase())
                );
            }
            tempArray = CheckRating(tempArray);
            return [...new Map(tempArray.map((item) => [item[key], item])).values()];
        }
    };

    const CheckRating = (ratingArray) => {
        const tempArray = ratingArray
            .filter(rating => CheckNull(rating?.overallRating) !== "" && rating?.overallRating > 0 && rating?.overallRating <= 5)
            .sort((a, b) => a.overallRating - b.overallRating)
            .map(item => ({
                ...item,
                overallRating: parseInt(item?.overallRating) // Modify as needed
            }));

        return tempArray;
    }

    useEffect(() => {
        if (productsList?.length > 0) {
            let selectedData =
            {
                selectedMainCategories: selectedMainCategories,
                selectedSubCategories: selectedSubCategories,
                selectedBrands: selectedBrands,
                selectedRatings: selectedRatings,
                searchTerm: searchTerm,
                sorting: sorting,
                currentPage: currentPage
            }
            HandleApplyFilter(selectedData);
        }
    }, [productsList])

    const handleLinkClick = (e) => {
        e.preventDefault();
        navigate(`/product-category/${decodeURIComponent(mainCategory?.toLowerCase()).replace(/ /g, '-')}`);
    };
    return (
        <>
            {page === "mainCategory" && <div className="pdt-main">
                <img src={mainCategoryImage.webBannerImg} style={{ 'width': '100%' }} />
            </div>}
            <Container className="all-product-list-container mt-1">

                {page === "mainCategory" && <Row>
                    <Col>
                        <div className="col-12 ">
                            <div className="overlap-title">
                                <h2 className="text-title pg" title={stripHtmlTags(mainCategoryImage.description)} dangerouslySetInnerHTML={{ __html: truncateText(mainCategoryImage.description, 270) }} />
                            </div>
                        </div>
                    </Col>
                </Row>}
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} xs={12} sm={12} className="bread-crumbs">
                        <Card>
                            <CardBody>
                                <Row className="align-items-center justify-content-end">
                                    {((displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0)) ? "" :
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <nav>
                                                <a className="breadcrumb-active d-inline-flex align-items-center gap-1">
                                                    {page === "allProducts" ? (
                                                        CheckNull(searchProducts) !== "" ? (
                                                            <>
                                                                Search Results for {searchProducts}{" "}
                                                                <span className="clear-search" onClick={() => HandleClearAll()}>
                                                                    Clear Search
                                                                </span>
                                                            </>
                                                        ) : (
                                                            "All Products"
                                                        )
                                                    ) : page === "mainCategory" ? (
                                                        mainCategoryImage?.catName
                                                    ) : page === "subCategory" ? (
                                                        subCategoryDetail?.mainCategory && subCategoryDetail?.catName ? (
                                                            <>
                                                                <Link
                                                                    to={`/product-category/${decodeURIComponent(
                                                                        mainCategory?.toLowerCase()
                                                                    ).replace(/ /g, "-")}`}
                                                                    onClick={handleLinkClick}
                                                                    className="breadcrumb-not-active"
                                                                >
                                                                    {subCategoryDetail?.mainCategory}
                                                                </Link>
                                                                {" > "}
                                                                {subCategoryDetail?.catName}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                </a>
                                            </nav>
                                        </Col>}
                                    <Col xxl={
                                        ((displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0)) ? 10 : 6} xl={((displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0)) > 0 ? 10 : 6} lg={((displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0)) > 0 ? 10 : 6} md={((displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0)) > 0 ? 8 : 4} sm={((displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0)) > 0 ? 8 : 12} xs={12}>
                                        {(displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0) && <div className="d-flex align-items-center gap-2 selected-filters">
                                            {(displayMainCategories?.length > 0 || displaySubCategories?.length > 0 || displayBrands?.length > 0) && (
                                                <p>Selected Filters:</p>
                                            )}
                                            {displayMainCategories && displayMainCategories?.map((category, index) => (
                                                <button key={index} className="btn">
                                                    {category.name}
                                                    <span aria-hidden="true" onClick={() => RemoveMainCategory(category)}>✖</span>
                                                </button>
                                            ))}
                                            {displaySubCategories && displaySubCategories?.map((category, index) => (
                                                <button key={index + displaySubCategories?.length} className="btn">
                                                    {category.name}
                                                    <span aria-hidden="true" onClick={() => RemoveSubCategory(category)}>✖</span>
                                                </button>
                                            ))}
                                            {displayBrands && displayBrands?.map((brand, index) => (
                                                <button key={index + displayMainCategories?.length + displaySubCategories.length} className="btn">
                                                    {brand.name}
                                                    <span aria-hidden="true" onClick={() => RemoveBrand(brand)}>✖</span>
                                                </button>
                                            ))}
                                        </div>}
                                    </Col>
                                    <Col xxl={1} xl={1} lg={1} md={2} sm={2} xs={3}>
                                        <Dropdown className="sort-dropdown">
                                            <Dropdown.Toggle className="ps-0">
                                                Sort by
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as="button" active={sorting === 'newestfirst'}>
                                                    <div onClick={() => HandleSorting('newestfirst')}>
                                                        Newest First
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" active={sorting === 'lowtohigh'}>
                                                    <div onClick={() => HandleSorting('lowtohigh')}>
                                                        Price: Low to High
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" active={sorting === 'hightolow'}>
                                                    <div onClick={() => HandleSorting('hightolow')}>
                                                        Price: High to Low
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col xxl={1} xl={1} lg={1} md={2} sm={2} xs={4} className="filter-dropdown">
                                        <Dropdown align={{ md: 'start', lg: 'start', xl: 'start', xxl: 'start' }}>
                                            <Dropdown.Toggle>
                                                Filter
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as="button">
                                                    <ProductNewFilter
                                                        page={page}
                                                        mainCategoryId={mainCategoryId}
                                                        subCategoryId={subCategoryId}
                                                        selectedMainCategories={selectedMainCategories}
                                                        selectedSubCategories={selectedSubCategories}
                                                        selectedBrands={selectedBrands}
                                                        selectedRatings={selectedRatings}
                                                        HandleMainCategoryChange={HandleMainCategoryChange}
                                                        HandleSubCategoryChange={HandleSubCategoryChange}
                                                        searchQuery={searchQuery}
                                                        HandleSearchChange={HandleSearchChange}
                                                        HandleClearAll={HandleClearAll}
                                                        HandleApplyFilter={HandleApplyFilter}
                                                        HandleBrandChange={HandleBrandChange}
                                                        HandleRatingChange={HandleRatingChange}
                                                        ApplyFilter={ApplyFilter}
                                                        getUniqueObjects={getUniqueObjects}
                                                        productFilterData={productFilterList}
                                                    />
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="product-sublist-row mt-1">
                    <Col md={12}>
                        <ProductGrid page={page} productsList={paginatedProductList} productListloading={productListloading} />
                        {productListDisplay?.length > 0 && !productListloading && (
                            <Row>
                                <Col md={12}>
                                    <Card className="pagination-list-card">
                                        <CardBody>
                                            <h2>
                                                Page {currentPage} of{" "}
                                                {totalPages}
                                            </h2>
                                            <ListPagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={HandlePageChange}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ProductListQuery;
