import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import GStore from "../assets/images/icons/Component 92 – 1.png";
import Qacertification from "../assets/images/icons/Theqa-certificatation.png"
import Visa from "../assets/images/icons/visa.png";
import Master from "../assets/images/icons/Image 2.png";
import Mastro from "../assets/images/icons/mastro.png";
import VisaEc from "../assets/images/icons/visa-ec.png";
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { GetData } from "../providers/queryData";
import { MobileApps, resourceconstant } from "../constants";
import useProducts from "../hooks/useProducts";
import useLogoImage from "../hooks/useLogoImage";
import Appstore from "../assets/images/icons/Component 90 – 1.png"
import ReactGA from 'react-ga4';
function Footer() {
  const [productsList, setProductsList] = useProducts();
  const navigate = useNavigate();
  const locations = useLocation()
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [filterMainCategory, setFilterMainCategory] = useState([])
  const [filterSubCategory, setFilterSubCategory] = useState([])
  const [categoryItem, setCategoryItem] = useState([])
  const [policyContent, setPolicyContent] = useState([]);
  const [logoUrl, setLogoUrl] = useLogoImage();
  const [policy, setPolicy] = useState([])
  const GetPolicyContent = () => {
    let filter = {
    }

    GetData(resourceconstant.POLICY_CONTENTS, filter, setPolicy)
  };

  useEffect(() => {
    if (policy?.severity === "success") {
      const content = policy?.data?.data?.listContents?.items
      const policyCon = content.filter(item => item.title && item.content);
      setPolicyContent(policyCon)
    }
  }, [policy])

  useEffect(() => {
    GetPolicyContent()
  }, [])

  const MainCategoryList = () => {
    let filter = {
      pk: {
        eq: 'MAIN#',
      },
    }
    GetData(resourceconstant.LIST_CATEGORY, filter, setFilterMainCategory)
  };

  useEffect(() => {
    if (filterMainCategory?.severity === "success") {
      const sortedCategories = filterMainCategory?.data?.data?.listCategories?.items?.sort((a, b) => {
        if (a.sequenceNumber != null && b.sequenceNumber != null) {
          return a.sequenceNumber - b.sequenceNumber;
        }
        if (a.sequenceNumber != null) return -1;
        if (b.sequenceNumber != null) return 1;
        return 0;
      });

      setCategory(sortedCategories);
    }
  }, [filterMainCategory]);

  const SubCategoryList = () => {
    let filter = {
      pk: { beginsWith: 'SUB#' }
    }
    GetData(resourceconstant.LIST_CATEGORY, filter, setFilterSubCategory)
  };

  useEffect(() => {
    if (filterSubCategory?.severity === "success" && category && productsList) {
      const subcategories = filterSubCategory?.data?.data?.listCategories?.items || [];

      const filteredCategories = category.filter((categorys) => {
        return productsList.some((subCategorys) => subCategorys.mainCategoryID === categorys.id);
      });
      setSubCategory(subcategories);
      setCategoryItem(filteredCategories);
    }
  }, [filterSubCategory, category, productsList]);

  useEffect(() => {
    if (category) {
      category.forEach((cat) => {
        SubCategoryList(cat.id);
      });
    }
  }, [category]);

  const HandleAllProducts = async (e) => {
    e.preventDefault()
    navigate('/all-products', {
      state: {
        allProducts: true
      }
    });
    window.scroll(0, 0)
  }

  useEffect(() => {
    MainCategoryList()
    SubCategoryList()
  }, [])

  useEffect(() => {
    if (locations.pathname !== 'reset-otp' && locations?.pathname !== 'reset-password') {
      localStorage.removeItem('timeLeftPassword')
    }
  }, [locations.pathname !== 'reset-otp', locations?.pathname !== 'reset-password'])

  const handleClickAnalytics = (platform) => {
    window.scroll(0, 0);
    ReactGA.event({
      category: 'Mep Products',
      action: 'Click',
      label: platform,
    });
  };

  return (
    <footer className="mainfooter">
      <div className="bottom-footer mt-1">
        <div className="container">
          <Row className="justify-content-between toplink large-view">
            <Col xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
              <ul className="footernav">
                <li className="footerlink title footer-title">Information</li>
                {/* <li className="footerlink">
                  <Link
                    to="/contact-us"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Contact us
                  </Link>
                </li> */}
                <li className='footerlink'>
                  <Link
                    to='/terms-condition'
                    onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Terms and Conditions
                  </Link>
                </li>
                <li className='footerlink'>
                  <Link
                    to='/refund-policy'
                    onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Refund Policy and Terms
                  </Link>
                </li>
                <li className='footerlink'>
                  <Link
                    to='/shipping-policy'
                    onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Shipping and Delivery Terms
                  </Link>
                </li>
                <li className='footerlink'>
                  <Link
                    to='/privacy-policy'
                    onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Privacy Policy and Terms
                  </Link>
                </li>
                <li className='footerlink'>
                  <Link
                    to='/cookie-policy'
                    onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Cookie Policy
                  </Link>
                </li>
                <li className="footerlink">
                  <Link
                    to="/faq"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <div className="text-start footer-title">
                <p className="footerlink title mt-2">Product Categories</p>
              </div>
              <div className="footer-container">
                {categoryItem?.length > 7 ? (
                  <>
                    <div className="footer-group">
                      <ul className="footernav" style={{ marginTop: 6 }}>
                        <li className="footerlink">
                          <Link to="/all-products" onClick={HandleAllProducts} className="me-2">
                            All
                          </Link>
                        </li>
                        {categoryItem.slice(0, Math.ceil(categoryItem.length / 2)).map((name, index) => {
                          const handleLinkClick = (e) => {
                            e.preventDefault();
                            navigate(
                              `/product-category/${decodeURIComponent(name.slug?.toLowerCase()).replace(/ /g, '-')}`,
                              {
                                state: {
                                  values: {
                                    category_id: `${name.id}`,
                                    search: `${name.id}`,
                                  },
                                },
                                search: `?category_id=${name.id}`,
                              }
                            );
                            window.scroll(0, 0);
                          };
                          return (
                            <li className="footerlink" key={index}>
                              <Link
                                to={`/product-category/${decodeURIComponent(name.slug?.toLowerCase()).replace(/ /g, '-')}`}
                                onClick={handleLinkClick}
                              >
                                {name.catName}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="footer-group">
                      <ul className="footernav">
                        {categoryItem.slice(Math.ceil(categoryItem.length / 2)).map((name, index) => {
                          const handleLinkClick = (e) => {
                            e.preventDefault();
                            navigate(
                              `/product-category/${decodeURIComponent(name.slug?.toLowerCase()).replace(/ /g, '-')}`,
                              {
                                state: {
                                  values: {
                                    category_id: `${name.id}`,
                                    search: `${name.id}`,
                                  },
                                },
                                search: `?category_id=${name.id}`,
                              }
                            );
                            window.scroll(0, 0);
                          };
                          return (
                            <li
                              className="footerlink"
                              key={index + Math.ceil(categoryItem.length / 2)}
                            >
                              <Link
                                to={`/product-category/${decodeURIComponent(name.slug?.toLowerCase()).replace(/ /g, '-')}`}
                                onClick={handleLinkClick}
                              >
                                {name.catName}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                ) : (
                  <div className="footer-group">
                    <ul className="footernav" style={{ marginTop: 6 }}>
                      <li className="footerlink">
                        <Link to="/all-products" onClick={HandleAllProducts} className="me-2">
                          All
                        </Link>
                      </li>
                      {categoryItem.map((name, index) => {
                        const handleLinkClick = (e) => {
                          e.preventDefault();
                          navigate(
                            `/product-category/${decodeURIComponent(name.slug?.toLowerCase()).replace(/ /g, '-')}`,
                            {
                              state: {
                                values: {
                                  category_id: `${name.id}`,
                                  search: `${name.id}`,
                                },
                              },
                              search: `?category_id=${name.id}`,
                            }
                          );
                          window.scroll(0, 0);
                        };
                        return (
                          <li className="footerlink" key={index}>
                            <Link
                              to={`/product-category/${decodeURIComponent(name.slug?.toLowerCase()).replace(/ /g, '-')}`}
                              onClick={handleLinkClick}
                            >
                              {name.catName}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
              <ul className="footernav customer-support">
                <li className="footerlink title footer-title">Customer Support</li>
                <li className="footerlink">
                  <Link
                    to="/profile"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    My Account
                  </Link>
                </li>
                <li className="footerlink">
                  <Link
                    to="/orders"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    My Orders
                  </Link>
                </li>
                {/* <li className="footerlink">
                  <Link
                    to="/contact-us"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Help Center
                  </Link>
                </li> */}
                <li className="footerlink">
                  <Link
                    to="/cart"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Checkout
                  </Link>
                </li>
                <li className="footerlink">
                  <Link
                    to="/wishlist"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Wishlist
                  </Link>
                </li>
                <li className="footerlink">
                  <Link
                    to="/contact-us"
                    onClick={() => handleClickAnalytics("Contact_us")}
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xxl={2} xl={2} lg={2} md={6} sm={6} xs={12}>
              <ul className="footernav download">
                <li className="footerlink title footer-title">Stay Connected</li>
                <li>
                  {logoUrl && (
                    <>
                      {logoUrl.mobileAppUrls?.find(item => item.type === MobileApps?.ANDROID) && (
                        <a
                          href={logoUrl.mobileAppUrls.find(item => item.type === MobileApps?.ANDROID)?.redirectTo}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={GStore} alt="Google Play Store" />
                        </a>
                      )}
                      {logoUrl.mobileAppUrls?.find(item => item.type === MobileApps?.IOS) && (
                        <a
                          href={logoUrl.mobileAppUrls.find(item => item.type === MobileApps?.IOS)?.redirectTo}
                          className="me-2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Appstore} alt="App Store" />
                        </a>
                      )}

                      {logoUrl.mobileAppUrls?.map((item) => (
                        item.type === MobileApps?.CERTIFICATE && (
                          <a
                            href={item.redirectTo}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={item.redirectTo}
                          >
                            <img src={item.url} alt="Certification" />
                          </a>
                        )
                      ))}
                    </>
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      <div className="copright-sec">
        <div className="container">
          <nav className="toplink region">
            <ul className="footernav regionnav order-2 order-md-1">
              <li className="regionlink copyright">
                <span>© {logoUrl?.copyrightText}</span>
              </li>
            </ul>
            <ul className="footernav cards order-1 order-md-2">
              <li className="regionlink">
                <img src={Visa} />
              </li>
              <li className="regionlink">
                <img src={Master} />
              </li>
              <li className="regionlink">
                <img src={Mastro} />
              </li>
              <li className="regionlink">
                <img src={VisaEc} />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer >
  );
}

export default Footer;
